import { Component, Input } from '@angular/core';
import { Product } from "../model/product-api";

@Component({
    selector: 'app-product-info-image',
    templateUrl: './product-info-image.component.html',
    styleUrls: ['./product-info-image.component.scss'],
    standalone: false
})
export class ProductInfoImageComponent {

  @Input()
  set product(product: Product | undefined) {
    if (product) this.setImage(product)
  }

  imageUrl: string | undefined

  private setImage(p: Product) {
    if (!p) return
    let image = p.attributes.image
    if (!image) return;
    image.images.forEach(image => {
      if (image.type == 'DRAW') {
        this.imageUrl = image.url
        return
      }
    })
  }

}

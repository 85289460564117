import { Component, Input } from '@angular/core';
import { Product } from "../model/product-api";
import { TableCardEntry } from "../../../component/card/model/card-api";
import { ProductTableEntry } from "../model/product-info-api";

@Component({
    selector: 'app-product-info-motor',
    templateUrl: './product-info-motor.component.html',
    styleUrls: ['./product-info-motor.component.scss'],
    standalone: false
})
export class ProductInfoMotorComponent {

  @Input()
  set product(product: Product | undefined) {
    if (product) this.setInfo(product)
  }

  title = 'motorData'
  data: TableCardEntry[] = []


  setInfo(product: Product) {
    let entries: { [key: string]: string } = {
      "motorVoltage": product?.attributes?.general?.motorVoltage || '',
      "maxOperatingCurrent": product?.attributes?.general?.maxOperatingCurrent.get('default') || '',
    }
    for (const key in entries)
    {
      this.pushData(key, entries[key])
    }
  }

  pushData(key: string, value: string | undefined) {
    this.data.push(
      new TableCardEntry(key, value || ""),
    )
  }
}

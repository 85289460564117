import {Component, OnDestroy, OnInit} from '@angular/core';
import {HomeService} from "../../../home/model/home.service";


@Component({
    selector: 'app-product-board',
    templateUrl: './product-board.component.html',
    styleUrls: ['./product-board.component.scss'],
    standalone: false
})
export class ProductBoardComponent implements OnInit, OnDestroy {

  constructor(
    private home: HomeService
  ) {

  }

  ngOnInit() {
    this.home.searchVisible = true
  }


  ngOnDestroy() {
    this.home.customHeaderActions = []
    this.home.searchVisible = false
  }

}

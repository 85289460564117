import { Component, Input } from '@angular/core';
import {
  Product,
  ProductDimension,
  ProductFan,
  ProductionInfo,
  ProductLimit,
  SupplementalParameter
} from "../model/product-api";
import { TableCardEntry, TableCardGroup } from "../../../component/card/model/card-api";

@Component({
    selector: 'app-product-technical-info',
    templateUrl: './product-technical-info.component.html',
    styleUrl: './product-technical-info.component.scss',
    standalone: false
})
export class ProductTechnicalInfoComponent {

  @Input()
  set product(product: Product | undefined) {
    if (product) this.setInfo(product)
  }

  title = 'ProdInformation'
  data: TableCardGroup[] = []

  setInfo(product: Product) {
    this.addDimensions(product.attributes.dimension)
    this.addSupplementalParameter(product.attributes.supplementalParameter)
    this.addLimit(product.attributes.limit)
    this.addProductionData(product.attributes.production)
    this.addFan(product.attributes.fan)
  }

  private addDimensions(dimension: ProductDimension | null) {
    if (!dimension) return
    let entries: TableCardEntry[] = []
    this.addData('depth', dimension.depth, entries)
    this.addData('height', dimension.height, entries)
    this.addData('width', dimension.width, entries)

    if (!entries.length) return
    this.data.push(new TableCardGroup('dimension', entries))
  }

  private addLimit(limit: ProductLimit | null) {
    if (!limit) return
    let entries: TableCardEntry[] = []
    this.addData('maxPressure', limit.maxPressure, entries)
    this.addData('maxTemperature', limit.maxTemperature, entries)

    if (!entries.length) return
    this.data.push(new TableCardGroup('limit', entries))
  }

  addSupplementalParameter(parameter: SupplementalParameter | null) {
    if (!parameter) return

    let spec: TableCardEntry[] = []
    this.addData('compressor.title', parameter.compressorSpecification, spec)
    if (!spec.length) return
    this.data.push(new TableCardGroup('', spec))

    let entries: TableCardEntry[] = []
    this.addData('sd', parameter.noiseLevel?.sd, entries)
    this.addData('sl', parameter.noiseLevel?.sl, entries)
    if (!entries.length) return
    this.data.push(new TableCardGroup('noiseLevel', entries))
  }

  private addData(key: string, value: string | undefined, entries: TableCardEntry[]) {
    if (!value) return
    entries.push(new TableCardEntry(key, value))
  }

  private addProductionData(production: ProductionInfo | null) {
    if (!production) return

    let entries: TableCardEntry[] = []
    this.addData('deliveryTime', production.deliveryTime, entries)
    if (!entries.length) return
    this.data.push(new TableCardGroup('production', entries))
  }

  private addFan(fan: ProductFan | null) {
    if (!fan) return

    let voltage: TableCardEntry[] = []
    this.addData('50Hz', fan.fanVoltage["50Hz"], voltage)
    this.addData('60Hz', fan.fanVoltage["60Hz"], voltage)
    if (!voltage.length) return
    this.data.push(new TableCardGroup('fanVoltage', voltage))

    let power: TableCardEntry[] = []
    this.addData('50Hz', fan.fanPower["50Hz"], power)
    this.addData('60Hz', fan.fanPower["60Hz"], power)
    if (!power.length) return
    this.data.push(new TableCardGroup('fanPower', power))

    let flow: TableCardEntry[] = []
    this.addData('50Hz', fan.fanFlow["50Hz"], flow)
    this.addData('60Hz', fan.fanFlow["60Hz"], flow)
    if (!flow.length) return
    this.data.push(new TableCardGroup('fanFlow', flow))
  }
}

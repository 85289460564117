import { Component, Input } from '@angular/core';
import { PipeConnection, Product, ProductDimension } from "../model/product-api";
import { TableCardEntry, TableCardGroup } from "../../../component/card/model/card-api";

@Component({
    selector: 'app-product-pipe-connections',
    templateUrl: './product-pipe-connections.component.html',
    styleUrl: './product-pipe-connections.component.scss',
    standalone: false
})
export class ProductPipeConnectionsComponent {

  @Input()
  set product(product: Product | undefined) {
    if (product) this.setInfo(product)
  }

  title = 'pipeConnections'
  data: TableCardGroup[] = []

  setInfo(product: Product) {
    product.attributes.pipe?.connections.forEach(con => {
      this.addConnection(con)
    })
  }

  private addConnection(connection: PipeConnection) {
    if (!connection) return
    let entries: TableCardEntry[] = []
    this.addData('size', connection.size, entries)
    this.addData('unit', connection.unit, entries)
    this.addData('material', connection.material, entries)

    if (!entries.length) return
    this.data.push(new TableCardGroup(connection.name, entries))
  }

  private addData(key: string, value: string | undefined, entries: TableCardEntry[]) {
    if (!value) return
    entries.push(new TableCardEntry(key, value))
  }
}
